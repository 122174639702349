var words: string[] = [
  "ちいさな",
  "がくせい",
  "ぶたにく",
  "かいもの",
  "たくさん",
  "だいがく",
  "あぶない",
  "かいだん",
  "まいとし",
  "はじまる",
  "なんでも",
  "はなれる",
  "ばいりつ",
  "おかしい",
  "ついらく",
  "おさない",
  "かんむり",
  "こごえる",
  "てつだう",
  "あまえる",
  "みちびく",
  "つなげる",
  "たがやす",
  "えんじん",
  "たいいく",
  "ていけい",
  "けんぜん",
  "けわしい",
  "どうてき",
  "けんこう",
  "へいじつ",
  "あんざん",
  "なにより",
  "あいする",
  "はいれつ",
  "ぼうふう",
  "かんしん",
  "ねんだい",
  "ばくはつ",
  "ゆうぼう",
  "はいすい",
  "ほうほう",
  "めいぶつ",
  "ほうたい",
  "さいさん",
  "そなえる",
  "どきどき",
  "つりあう",
  "さんせい",
  "ぶろーち",
  "ふうせん",
  "ぺこぺこ",
  "れいぼう",
  "はつでん",
  "いれもの",
  "びんせん",
  "ささげる",
  "いりぐち",
  "ふうけい",
  "ほうどう",
  "たんこう",
  "いろいろ",
  "けんぽう",
  "さんぷく",
  "つとめる",
  "どうして",
  "なこうど",
  "こうせい",
  "ねったい",
  "しっかり",
  "じむしょ",
  "こげちゃ",
  "けってい",
  "きりゅう",
  "きょうし",
  "しゅうし",
  "すえっこ",
  "ちょうか",
  "きゅうち",
  "りっとる",
  "きょぜつ",
  "じゅんび",
  "りじゅん",
  "ほうこく",
  "ぞうげん",
  "きじゅん",
  "かくりつ",
  "あんぜん",
  "いっかつ",
  "せんじつ",
  "あやうい",
  "ふくしゃ",
  "とうそう",
  "せいてき",
  "だんけつ",
  "りったい",
  "くっきり",
  "がんらい",
  "じーんず",
  "しゅげい",
  "かんねん",
  "ていあん",
  "ひとがら",
  "おいこす",
  "せきたん",
  "ざんねん",
  "しょうぎ",
  "わるもの",
  "かいしゃ",
  "てっきり",
  "ほうせき",
  "てんてん",
  "てきよう",
  "よきょう",
  "みおくる",
  "おんたい",
  "かたよる",
  "なんとか",
  "しゅうり",
  "はつめい",
  "ぼーなす",
  "いたって",
  "ひきだす",
  "ほうそく",
  "あんせい",
  "かいてき",
  "ふなびん",
  "りょうち",
  "たいけい",
  "つうきん",
  "おさめる",
  "いちれん",
  "かっこう",
  "まちかど",
  "きゅうじ",
  "ちちおや",
  "こんけつ",
  "つかえる",
  "いきさつ",
  "とのさま",
  "がっかり",
  "ひひょう",
  "ふちょう",
  "じつげん",
  "あみもの",
  "けっそく",
  "しゃせい",
  "のうりつ",
  "みょうじ",
  "らいにち",
  "あっさり",
  "こうくう",
  "すくーる",
  "ゆうかん",
  "じんそく",
  "しょとく",
  "たっしゃ",
  "つうかん",
  "いきなり",
  "うっかり",
  "ぜひとも",
  "つめたい",
  "しーずん",
  "にっとう",
  "かなしむ",
  "たのしみ",
  "もくよう",
  "せいいく",
  "なやます",
  "らいたー",
  "だんたい",
  "ふるさと",
  "ていいん",
  "もうしで",
  "ごまかす",
  "いってい",
  "せってい",
  "ぱちんこ",
  "かくしゅ",
  "けんがく",
  "しあがる",
  "そのうち",
  "こくさん",
  "ゆうそう",
  "こうはい",
  "げっしゃ",
  "おもわず",
  "しょうぶ",
  "げんしょ",
  "かいあく",
  "げんしゅ",
  "かぶしき",
  "はくがい",
  "おもちゃ",
  "かんとく",
  "たしょう",
  "でかける",
  "そだてる",
  "たいいん",
  "きんだい",
  "かいそう",
  "すぐれる",
  "どういん",
  "ひつぜん",
  "りっぽう",
  "せいかく",
  "ぶちょう",
  "あきらめ",
  "かきとる",
  "さんこう",
  "がっこう",
  "そんがい",
  "めいれい",
  "しんてい",
  "たびたび",
  "がくしゃ",
  "ここのつ",
  "うぬぼれ",
  "ぜいきん",
  "けいやく",
  "まごころ",
  "けいせい",
  "ぎょうぎ",
  "きゅうよ",
  "がいこく",
  "ばらんす",
  "たしざん",
  "もくろみ",
  "ばくだい",
  "かんばん",
  "えいぶん",
  "もうける",
  "しゅだん",
  "こぎって",
  "じょげん",
  "たいくつ",
  "かようび",
  "ちょちく",
  "あいにく",
  "ちぇっく",
  "いちべつ",
  "けむたい",
  "まんげつ",
  "るいすい",
  "いやがる",
  "つらなる",
  "まちまち",
  "かぶせる",
  "しゃたく",
  "つかれる",
  "なんだか",
  "かたまる",
  "どりょく",
  "とうぼう",
  "じゃんる",
  "おんだん",
  "すずしい",
  "いんさつ",
  "かんする",
  "こうふく",
  "とびこむ",
  "しゅじゅ",
  "かいがら",
  "にんしん",
  "したてる",
  "ごうけい",
  "いちおう",
  "ぶつぞう",
  "かいてい",
  "れんらく",
  "すかーと",
  "さいこう",
  "とにかく",
  "かいけん",
  "きょねん",
  "だいいち",
  "すなわち",
  "しょうご",
  "どうよう",
  "ふひょう",
  "つぶやく",
  "たいせつ",
  "まあまあ",
  "せいみつ",
  "おんしつ",
  "はいけい",
  "すいげん",
  "たれんと",
  "かいさい",
  "こうさい",
  "はんけい",
  "まいぞう",
  "べんじょ",
  "あいたい",
  "あさって",
  "うらぐち",
  "せいもん",
  "かつどう",
  "しんこく",
  "えんそく",
  "えんずる",
  "こうよう",
  "ほんたい",
  "とうぶん",
  "ひのいり",
  "すくない",
  "はんえい",
  "うりだし",
  "ぞんざい",
  "いとなむ",
  "かんこく",
  "にづくり",
  "がっちり",
  "こうつう",
  "おおよそ",
  "やっぱり",
  "ぐんぷく",
  "とうごう",
  "てっする",
  "だんめん",
  "もとめる",
  "れんめい",
  "こころえ",
  "ほうしゃ",
  "いまさら",
  "ねむたい",
  "ほほえむ",
  "そうさく",
  "さいせい",
  "はつばい",
  "ふまえる",
  "むじゃき",
  "そうおん",
  "じっくり",
  "かみそり",
  "いっしょ",
  "じゅもく",
  "いきもの",
  "よういん",
  "しんずる",
  "かしだし",
  "せいやく",
  "こうえん",
  "つうこう",
  "さっする",
  "うけとる",
  "てんすう",
  "ひらがな",
  "うちけし",
  "とうなん",
  "ひとこと",
  "とうとぶ",
  "くやしい",
  "げつまつ",
  "せいそう",
  "おおぞら",
  "たとえば",
  "ていしゃ",
  "あんなに",
  "かたまり",
  "せいてつ",
  "おさまる",
  "しんこん",
  "ひたすら",
  "ちょうさ",
  "てんかん",
  "しゃしん",
  "ちかすい",
  "あいぞう",
  "こーひー",
  "さいけつ",
  "こうりつ",
  "とうあん",
  "よろしい",
  "さいかい",
  "びりょう",
  "まんいち",
  "めいさく",
  "かんそう",
  "おうごん",
  "しゅかん",
  "しんじゃ",
  "とりだす",
  "ぜいかん",
  "てつぼう",
  "のりもの",
  "たいした",
  "ふるまう",
  "ようえき",
  "おおぜい",
  "まぎれる",
  "ねっとう",
  "もくざい",
  "こっけい",
  "きじゅつ",
  "かんづめ",
  "たんすい",
  "くじょう",
  "にゅーす",
  "かたかな",
  "ちゃんす",
  "ひんしゅ",
  "はんがー",
  "まんなか",
  "かいさん",
  "がいらい",
  "ねんごう",
  "したがう",
  "とういつ",
  "えいしゃ",
  "ききょう",
  "たおれる",
  "やくだつ",
  "がそりん",
  "しゅよう",
  "うめぼし",
  "すとろぼ",
  "ぼうえき",
  "すぴーど",
  "みかづき",
  "えんだん",
  "たっぷり",
  "たいのう",
  "そろそろ",
  "ちらかる",
  "なごやか",
  "もうれつ",
  "もーてる",
  "きょうわ",
  "へいこう",
  "かんせん",
  "しゅえん",
  "しょうり",
  "ひじゅう",
  "なげだす",
  "げんてん",
  "とくしゅ",
  "りょうど",
  "かんらん",
  "とろける",
  "としつき",
  "しんにん",
  "しんぶん",
  "とうにん",
  "あやまる",
  "ちつじょ",
  "きょうい",
  "かんぱい",
  "かんべん",
  "おかえり",
  "だいたい",
  "ごぶさた",
  "しんしん",
  "うりあげ",
  "こだわる",
  "えんりょ",
  "きにゅう",
  "ちりょう",
  "ちかごろ",
  "すてーじ",
  "つとまる",
  "くらべる",
  "すいどう",
  "おごそか",
  "わりびき",
  "でんげん",
  "げんいん",
  "けいしゃ",
  "けんそん",
  "らんぼう",
  "こうずい",
  "さくしゃ",
  "とつじょ",
  "れいぞう",
  "たいまー",
  "ひんしつ",
  "むらがる",
  "けいそつ",
  "とりくむ",
  "がくもん",
  "さわやか",
  "ほうじる",
  "めんじょ",
  "はいりょ",
  "からかう",
  "くわえる",
  "ひっこす",
  "たいしょ",
  "こうこう",
  "ついせき",
  "こんなん",
  "しんぞう",
  "なぞなぞ",
  "ひょっと",
  "つうよう",
  "やすめる",
  "びんぼう",
  "ゆうえつ",
  "けいばつ",
  "かきとめ",
  "いらいら",
  "せんげん",
  "ほどこす",
  "しぶとい",
  "すりっぱ",
  "うったえ",
  "だんせい",
  "たいぼく",
  "こくれん",
  "ほうけん",
  "あやつる",
  "ぎっしり",
  "くだもの",
  "しつれん",
  "うちこむ",
  "りょかん",
  "もくろく",
  "しめきる",
  "もてなす",
  "ちかづく",
  "ひきざん",
  "かんろく",
  "ふみきり",
  "まっしろ",
  "えいせい",
  "だんてい",
  "つきなみ",
  "びみょう",
  "めっきり",
  "そうぞく",
  "かんよう",
  "さかずき",
  "ぷりんと",
  "すいそく",
  "しゅっせ",
  "ひっかく",
  "ほうもん",
  "こきゅう",
  "ぜんたい",
  "ばくしゅ",
  "そんなに",
  "ねじれる",
  "さいほう",
  "てまわし",
  "しんせき",
  "もんどう",
  "さーびす",
  "かくとく",
  "りょこう",
  "じしゅう",
  "たいりつ",
  "へんせん",
  "すとーぶ",
  "さくひん",
  "こたえる",
  "ようする",
  "ぶんるい",
  "にせもの",
  "けいざい",
  "たいせい",
  "いけない",
  "せつない",
  "だっせん",
  "きんにく",
  "まかせる",
  "かくれる",
  "せいぜい",
  "きっかり",
  "すいよう",
  "かいかん",
  "ろくおん",
  "しゃがむ",
  "さいてん",
  "やじるし",
  "じんざい",
  "ぶんしょ",
  "ならべる",
  "こうそく",
  "ごうどう",
  "えんかい",
  "ほうめん",
  "しょてん",
  "ざひょう",
  "ぶかぶか",
  "ばっする",
  "なんべい",
  "きしょう",
  "おまつり",
  "うれゆき",
  "へきえき",
  "がくねん",
  "ぼうえい",
  "かみくず",
  "てんさい",
  "じょおう",
  "おどかす",
  "おるがん",
  "いりょう",
  "けんざい",
  "どくしん",
  "じょうぶ",
  "とうだい",
  "ひきずる",
  "ゆにーく",
  "どうめい",
  "おしえる",
  "ついたち",
  "これから",
  "ようやく",
  "なかゆび",
  "こうたく",
  "えんがわ",
  "しんどう",
  "しんゆう",
  "なかよし",
  "けんとう",
  "はいいろ",
  "とうみん",
  "ふぉーむ",
  "しょうか",
  "うかべる",
  "たかめる",
  "ひといき",
  "ゆしゅつ",
  "ろんずる",
  "いちいち",
  "ここのか",
  "てんにん",
  "はげしい",
  "じっぷん",
  "かたむく",
  "たいそう",
  "われわれ",
  "やさしい",
  "さっそく",
  "うけもつ",
  "こうかい",
  "えんじょ",
  "てんいん",
  "じゃんぼ",
  "まいつき",
  "めーとる",
  "はんどる",
  "かんじゃ",
  "ほんやく",
  "げしゅく",
  "げんてい",
  "こいびと",
  "いきごむ",
  "ときおり",
  "ひっこし",
  "さいがい",
  "まるめる",
  "まっさき",
  "ひきょう",
  "おつかい",
  "いじょう",
  "ていこう",
  "はんらん",
  "きんじょ",
  "ぶんぽう",
  "しいれる",
  "たいぼう",
  "ひきだし",
  "ちらかす",
  "さいくつ",
  "れんたい",
  "さいぼう",
  "そうりつ",
  "せつめい",
  "おもむき",
  "うごかす",
  "ぴすとる",
  "えぷろん",
  "はずれる",
  "よろこび",
  "せいだい",
  "でなおし",
  "ちゅうと",
  "たいして",
  "さいはつ",
  "かぶれる",
  "ぐらんど",
  "こまかい",
  "ひりょう",
  "れんじつ",
  "うんぬん",
  "きぎょう",
  "おくさん",
  "そうして",
  "ふんしつ",
  "けっして",
  "いっしん",
  "はんとう",
  "えんぜつ",
  "こううん",
  "ようせき",
  "ほうそう",
  "さんかく",
  "ひろげる",
  "ふぇりー",
  "くわわる",
  "けんちく",
  "ゆいいつ",
  "ものおと",
  "そうだん",
  "ひんぱん",
  "きちょう",
  "あばれる",
  "きょうふ",
  "とどまる",
  "とびだす",
  "いいだす",
  "あじわい",
  "しんせつ",
  "れんぽう",
  "ろっかー",
  "こんげん",
  "いちめん",
  "もよおし",
  "ゆうゆう",
  "おてあげ",
  "たんだい",
  "ぎょせん",
  "おぼれる",
  "そろばん",
  "そうとう",
  "いしょう",
  "にこにこ",
  "てんねん",
  "ところで",
  "こくぼう",
  "ひとごみ",
  "ようほう",
  "せんざい",
  "ひとすじ",
  "がいする",
  "ほんぶん",
  "かんたい",
  "しんぱい",
  "あっこう",
  "ようふく",
  "くだける",
  "つよまる",
  "じょうげ",
  "しんがり",
  "しんぜん",
  "けいさつ",
  "えんげい",
  "しびれる",
  "ちくさん",
  "ろけっと",
  "たいけん",
  "つらぬく",
  "しきさい",
  "それでは",
  "れこーど",
  "かいかい",
  "ちかよる",
  "りゃくす",
  "ざぶとん",
  "ろうどく",
  "きのどく",
  "おさきに",
  "しゃかい",
  "こんらん",
  "つうがく",
  "ふくめる",
  "ののしる",
  "こうさく",
  "はんたい",
  "あつまり",
  "でむかえ",
  "じしゃく",
  "ぶったい",
  "なりたつ",
  "ふりがな",
  "あめりか",
  "あしあと",
  "さかだち",
  "どうとく",
  "がくげい",
  "なくなる",
  "せいめい",
  "おうべい",
  "さかえる",
  "れんあい",
  "にぎやか",
  "おいしい",
  "にちよう",
  "ちくせき",
  "こくゆう",
  "とうよう",
  "なだらか",
  "たずねる",
  "せいひん",
  "かたみち",
  "おばさん",
  "しらせる",
  "みんぞく",
  "なにしろ",
  "おちつく",
  "はらっぱ",
  "ようぶん",
  "うでまえ",
  "たいまん",
  "ばくだん",
  "ちゃんと",
  "きこえる",
  "ながびく",
  "ちぢまる",
  "じんこう",
  "せつりつ",
  "まっさお",
  "すまーと",
  "ろうどう",
  "かいじょ",
  "めいしん",
  "のうやく",
  "ためいき",
  "こじれる",
  "はっそう",
  "ふぁいと",
  "ざいもく",
  "とじまり",
  "みのがす",
  "そろえる",
  "でんとう",
  "かいよう",
  "さんりん",
  "しつける",
  "へんこう",
  "たいおん",
  "いためる",
  "たんなる",
  "もたれる",
  "せいねん",
  "こんてい",
  "そこなう",
  "つうせつ",
  "あくにち",
  "まさしく",
  "おくがい",
  "ほじゅう",
  "とうけい",
  "しんだん",
  "みっせつ",
  "はらだち",
  "どうかく",
  "びょうき",
  "てんごく",
  "ながれる",
  "ずるずる",
  "ぶんかい",
  "どうこう",
  "いかにも",
  "このごろ",
  "わりあい",
  "よこぎる",
  "にんめい",
  "なづける",
  "こくふく",
  "ふくそう",
  "おいつく",
  "ながめる",
  "しゃぜつ",
  "へだたる",
  "たいとる",
  "ゆうぼく",
  "にってい",
  "ぞうだい",
  "もっとも",
  "きんゆう",
  "かんれい",
  "げんだい",
  "はってん",
  "しあげる",
  "せいてい",
  "じょうき",
  "けがれる",
  "さえずる",
  "ひょうご",
  "ほうぼう",
  "べんかい",
  "いっさい",
  "うまれる",
  "あきらか",
  "さいばい",
  "げきれい",
  "しんせん",
  "すいてき",
  "さだめる",
  "きょうぎ",
  "ちぢめる",
  "あつまる",
  "さきほど",
  "そうぞう",
  "にっこり",
  "ようせい",
  "すとれす",
  "しずまる",
  "ひろめる",
  "かためる",
  "こいする",
  "よわまる",
  "いっしゅ",
  "さいじつ",
  "ちりがみ",
  "たちまち",
  "おちこむ",
  "ひあたり",
  "さいたく",
  "こんげつ",
  "きたえる",
  "それゆえ",
  "なおさら",
  "かいごう",
  "すかーふ",
  "にっこう",
  "ふくごう",
  "おおきい",
  "ぼやける",
  "りょうじ",
  "のりかえ",
  "あふりか",
  "てちょう",
  "けいかん",
  "かんぺき",
  "あいまい",
  "のうみん",
  "ぼうとう",
  "ちょぞう",
  "かみなり",
  "めんせき",
  "ぬけだす",
  "ぜっぱん",
  "しゃせつ",
  "しなもの",
  "すたーと",
  "にかよう",
  "かいさつ",
  "あいろん",
  "はいけん",
  "りゃくご",
  "ほんのう",
  "かいほう",
  "くーらー",
  "おおきな",
  "こうげん",
  "うしなう",
  "じんかく",
  "せつぞく",
  "もーたー",
  "うるさい",
  "こくもつ",
  "ちゃいろ",
  "めいずる",
  "そうしき",
  "はっこう",
  "けっさん",
  "ねんれい",
  "もちいる",
  "にげだす",
  "たてもの",
  "さいぜん",
  "かえって",
  "ばんざい",
  "はいざら",
  "おくれる",
  "こうして",
  "ふぉーく",
  "どくしゃ",
  "きわめて",
  "こんかい",
  "がくせつ",
  "のがれる",
  "どうぶつ",
  "うけとり",
  "かんずる",
  "ささやく",
  "あるかり",
  "めーかー",
  "こうぜん",
  "くるしむ",
  "あかるい",
  "びにーる",
  "さいわい",
  "せっくす",
  "りょかく",
  "おそわる",
  "かけざん",
  "すんぽう",
  "さつえい",
  "なんぼく",
  "あやまち",
  "しゃべる",
  "じっせき",
  "かのじょ",
  "わーぷろ",
  "いっぽう",
  "げんかん",
  "ぶっしつ",
  "へいたい",
  "しょうじ",
  "ぼうぜん",
  "まらそん",
  "とらぶる",
  "はっしゃ",
  "あたえる",
  "いっぱん",
  "こうちゃ",
  "じんめい",
  "うちゅう",
  "ぱじゃま",
  "けいしき",
  "せめんと",
  "そうかん",
  "どらいぶ",
  "おととい",
  "しんてん",
  "しんぱん",
  "あやふや",
  "じゅうじ",
  "おだてる",
  "めじるし",
  "すこしも",
  "どわすれ",
  "なかほど",
  "ぜんめつ",
  "せっかい",
  "ふんまつ",
  "いっぱい",
  "なるほど",
  "へだてる",
  "はっくつ",
  "みわたす",
  "めいさん",
  "ないろん",
  "あざむく",
  "こごらす",
  "しゃれる",
  "りょけん",
  "こうどう",
  "まことに",
  "ゆうどう",
  "こうもく",
  "さんがく",
  "くつした",
  "まもなく",
  "はじめる",
  "こめんと",
  "ざんこく",
  "ともなう",
  "はなやか",
  "じょうぎ",
  "もうすぐ",
  "ぐっすり",
  "でっさん",
  "としより",
  "としごろ",
  "ちょしゃ",
  "ざいさん",
  "まごつく",
  "せいげん",
  "れっとう",
  "ちんぎん",
  "はなびら",
  "くっつく",
  "はみがき",
  "ちょめい",
  "かくてい",
  "まいにち",
  "けつえき",
  "うたがう",
  "じつよう",
  "どんかん",
  "ふっこう",
  "もっぱら",
  "ばくぜん",
  "ものごと",
  "たくしー",
  "とうてい",
  "かんごし",
  "しんよう",
  "でんえん",
  "くるしい",
  "ははおや",
  "きっかけ",
  "しんごう",
  "がんしょ",
  "ぎじゅつ",
  "つづける",
  "ひとまず",
  "びじゅつ",
  "くろうと",
  "はついく",
  "ふくざつ",
  "うりきれ",
  "かなづち",
  "れんぞく",
  "けっせい",
  "ぜいたく",
  "しょもつ",
  "かいどう",
  "きんずる",
  "ほんだな",
  "なんなり",
  "ざいがく",
  "こくてい",
  "いただく",
  "かんけい",
  "かんがえ",
  "すいそう",
  "びっくり",
  "まちがい",
  "よつかど",
  "ぶんさん",
  "ひっぱる",
  "がんせき",
  "かいいん",
  "こうげい",
  "あんしん",
  "そうおう",
  "じゅわき",
  "おこなう",
  "ろうそく",
  "おおかた",
  "めいじる",
  "こんきょ",
  "えんがん",
  "ししゅつ",
  "めでたい",
  "きょうり",
  "たいする",
  "つじつま",
  "さぎょう",
  "しんじつ",
  "やっかい",
  "げっそり",
  "うちきる",
  "たのしい",
  "ぶんすう",
  "かくしん",
  "ざいげん",
  "うんめい",
  "さえぎる",
  "まるごと",
  "こしょう",
  "はんげき",
  "でくわす",
  "ちっとも",
  "いたわる",
  "おまいり",
  "せんそう",
  "いにしえ",
  "ゆうやけ",
  "さっかく",
  "ろんぶん",
  "ひやかす",
  "けんやく",
  "ゆうはん",
  "とちゅう",
  "ぶりょく",
  "こうざん",
  "しゅうじ",
  "うんざり",
  "へんかん",
  "こうどく",
  "えんかつ",
  "ふうとう",
  "ばんぐみ",
  "そっくり",
  "おうたい",
  "げつよう",
  "ぱたーん",
  "それとも",
  "れいとう",
  "すんなり",
  "あべこべ",
  "せいほう",
  "よのなか",
  "しんらい",
  "さっぱり",
  "しょさい",
  "しっぱい",
  "せんたく",
  "みつける",
  "はんばい",
  "げんぶん",
  "ばんにん",
  "べんろん",
  "ぶんたい",
  "ばんのう",
  "なるべく",
  "おうずる",
  "せっきん",
  "どくそう",
  "けいれき",
  "すばやい",
  "けんめい",
  "ひかえる",
  "じつれい",
  "おみやげ",
  "さずける",
  "そんざい",
  "ずいひつ",
  "しずめる",
  "しりーず",
  "かさねる",
  "やぶれる",
  "ようふう",
  "びしょう",
  "たやすい",
  "てんらく",
  "すまない",
  "ねっする",
  "おーばー",
  "ようじん",
  "おおはば",
  "すぺーす",
  "しかくい",
  "がっくり",
  "きにいる",
  "けっこう",
  "ちょうど",
  "きっちり",
  "こうじつ",
  "こうげき",
  "そくばく",
  "かんれき",
  "そうべつ",
  "ちょうき",
  "ちゅうわ",
  "たべもの",
  "だいきん",
  "ふるえる",
  "よくせい",
  "いじゅう",
  "あんてい",
  "しょぶん",
  "ろうじん",
  "きずつく",
  "ぶつぶつ",
  "うんよう",
  "ふかけつ",
  "ひつよう",
  "ゆうせい",
  "しゅるい",
  "かぞえる",
  "ぐうすう",
  "はさまる",
  "れんごう",
  "はんぱつ",
  "けいけん",
  "けんげん",
  "ちがえる",
  "かんれん",
  "とうろく",
  "びんかん",
  "しゅけん",
  "みなさん",
  "あずける",
  "にぎわう",
  "こうけい",
  "ねくたい",
  "てきせつ",
  "かけあし",
  "まいあさ",
  "ずひょう",
  "りょうし",
  "じっかん",
  "かみのけ",
  "てきする",
  "せいしょ",
  "かつよう",
  "どうどう",
  "てぶくろ",
  "よろこぶ",
  "けいこく",
  "ぴったり",
  "てじゅん",
  "ともだち",
  "かいがん",
  "あんさつ",
  "ひさしい",
  "しょどう",
  "こうしき",
  "つてこと",
  "とくべつ",
  "しすてむ",
  "きちんと",
  "ていねい",
  "つよめる",
  "すっぱい",
  "ほんらい",
  "ひょうか",
  "ぞくする",
  "まとめる",
  "しんるい",
  "やくしゃ",
  "しょうこ",
  "ばらまく",
  "むちゅう",
  "かたづく",
  "かたわら",
  "じゅつご",
  "せいれつ",
  "だいよう",
  "おいわい",
  "げんさく",
  "いくせい",
  "りょうり",
  "はんけつ",
  "なかなか",
  "こうだん",
  "ぶんれつ",
  "めいじん",
  "とくさん",
  "うえした",
  "こんごう",
  "かんかく",
  "ところが",
  "せいする",
  "おこない",
  "たいてい",
  "ふんそう",
  "ほうあん",
  "きゃっち",
  "ちょうり",
  "とぼしい",
  "べつべつ",
  "のりこむ",
  "さいくる",
  "かいふく",
  "きょよう",
  "ていせい",
  "せんげつ",
  "てっぽう",
  "てっぺん",
  "ねんかん",
  "げきぞう",
  "さんぷる",
  "けんきょ",
  "ほうりつ",
  "しょせき",
  "げんざい",
  "こうこく",
  "ぐるーぷ",
  "ゆうめい",
  "ねまわし",
  "いちだん",
  "れいせい",
  "うめこむ",
  "こうしん",
  "せいけい",
  "せんだい",
  "れっしゃ",
  "ちりとり",
  "さいのう",
  "きんもつ",
  "ししゅう",
  "こうふん",
  "すいえい",
  "きんぞく",
  "すたいる",
  "てんきょ",
  "つっぱる",
  "たんいつ",
  "とうしょ",
  "しょうひ",
  "たいはん",
  "さんすう",
  "はったつ",
  "びじねす",
  "つうしん",
  "さくいん",
  "もにたー",
  "せいりつ",
  "やちゅう",
  "かいぞう",
  "かんげん",
  "てきかく",
  "けつぼう",
  "かわかす",
  "いたずら",
  "うそつき",
  "とりかえ",
  "もよおす",
  "けいかい",
  "こうきょ",
  "かいせつ",
  "かかわる",
  "けいさい",
  "あらゆる",
  "ゆううつ",
  "かかえる",
  "すたんど",
  "けいえい",
  "こうすい",
  "まっすぐ",
  "しゃぶる",
  "さくげん",
  "まえおき",
  "かんしゃ",
  "さんぶつ",
  "うーまん",
  "たっする",
  "めんせつ",
  "ろーまじ",
  "ずらっと",
  "すとっぷ",
  "しにょう",
  "あんない",
  "かたこと",
  "さむらい",
  "さしひき",
  "よごれる",
  "ろうすい",
  "つくろう",
  "はつげん",
  "うれしい",
  "だんすい",
  "ぜんてい",
  "しょざい",
  "はいじょ",
  "おれんじ",
  "ほんかく",
  "かみきる",
  "すっきり",
  "ただしい",
  "がれーじ",
  "ふりょく",
  "はんさむ",
  "こくおう",
  "ほろびる",
  "けいたい",
  "こわれる",
  "みとめる",
  "かいばつ",
  "ぜんしゃ",
  "かさばる",
  "ふんだん",
  "とっくに",
  "はっせい",
  "なんばー",
  "そんとく",
  "だんかい",
  "ぼうどう",
  "とりけす",
  "まふらー",
  "きんがん",
  "とっきょ",
  "ささえる",
  "けっせき",
  "ふじゆう",
  "もうかる",
  "ごうせい",
  "かんぜん",
  "めんぜい",
  "てんこう",
  "ろーんち",
  "こちょう",
  "ゆうせん",
  "ことづて",
  "ごちそう",
  "おおがら",
  "でんせつ",
  "しなりお",
  "おうえん",
  "せいせき",
  "ぎょうむ",
  "きんよう",
  "つっこむ",
  "しつこい",
  "じんぞう",
  "ぞんずる",
  "さんだる",
  "よびだす",
  "かいたく",
  "おもなる",
  "きんがく",
  "はたらき",
  "だんぼう",
  "こんざつ",
  "ちぇんじ",
  "ばんごう",
  "とどめる",
  "しょっぷ",
  "かんさい",
  "じゅくご",
  "ずいぶん",
  "かせっと",
  "いもうと",
  "ものさし",
  "とうせん",
  "たたかい",
  "へいきん",
  "まいばん",
  "がくれき",
  "ふけいき",
  "かしこい",
  "ぼうせき",
  "べんとう",
  "はんぶん",
  "しょめい",
  "せいこう",
  "さまざま",
  "きんじる",
  "たいきん",
  "だんげん",
  "あつかい",
  "ほしもの",
  "しくじる",
  "とうこう",
  "かくてる",
  "かいかく",
  "のきなみ",
  "せっけい",
  "あわてる",
  "まばたき",
  "きよらか",
  "こうそう",
  "ばいばい",
  "しらべる",
  "とがめる",
  "ぼうさん",
  "いまにも",
  "おそれる",
  "せっする",
  "きょうみ",
  "ひいては",
  "しんけい",
  "おもいで",
  "れんそう",
  "きんえん",
  "じゅんさ",
  "かにゅう",
  "へんとう",
  "じんるい",
  "すこやか",
  "そくてい",
  "いやいや",
  "いんふれ",
  "ぶんぱい",
  "すぽーつ",
  "かんせつ",
  "ふさがる",
  "のっとる",
  "だいたん",
  "いけばな",
  "かんけつ",
  "しっぴつ",
  "せいよう",
  "よくあつ",
  "じょせい",
  "まとまる",
  "しんさつ",
  "けんせつ",
  "かっぱつ",
  "ぜんぜん",
  "わくせい",
  "めにゅー",
  "せいしき",
  "どくりつ",
  "つぶれる",
  "べてらん",
  "まんいん",
  "げんけい",
  "くんしゅ",
  "ごくらく",
  "たたかう",
  "おおげさ",
  "ないよう",
  "とうざい",
  "ちっそく",
  "むかえる",
  "とうろん",
  "ふっとう",
  "ごぞんじ",
  "しまった",
  "がいせつ",
  "はじらう",
  "たまわる",
  "みおとす",
  "おーぷん",
  "いくぶん",
  "おこたる",
  "ふぁいる",
  "らいげつ",
  "おおみず",
  "きまぐれ",
  "じんみん",
  "こうじょ",
  "さいけん",
  "はいぶん",
  "ぶんけん",
  "ことがら",
  "たんとう",
  "みじゅく",
  "かいがい",
  "えつらん",
  "わすれる",
  "じょこう",
  "ひんこん",
  "きゅうか",
  "てきとう",
  "こっせつ",
  "いっぺん",
  "こうぞう",
  "ひきわけ",
  "ゆうじん",
  "とりわけ",
  "せんきょ",
  "えいよう",
  "ほんかん",
  "ちらっと",
  "およぼす",
  "ただちに",
  "どれどれ",
  "ぼうはん",
  "みつもり",
  "おうふく",
  "みおろす",
  "あいであ",
  "かじょう",
  "へいほう",
  "かちょう",
  "すけーと",
  "おんがく",
  "えんじる",
  "だいなし",
  "かけっこ",
  "かんてん",
  "つながり",
  "はくしゅ",
  "やくそく",
  "ほんとう",
  "かいとう",
  "せいじつ",
  "たんぺん",
  "さくせん",
  "かがやく",
  "うけつぐ",
  "おかわり",
  "てじょう",
  "あやしい",
  "げんかい",
  "てんきん",
  "ふたたび",
  "ほっそく",
  "おそらく",
  "あらそう",
  "なんとも",
  "うんえい",
  "こうがい",
  "たいかく",
  "ふりだし",
  "さかさま",
  "ちきゅう",
  "けいげん",
  "けっこん",
  "とくてい",
  "けしごむ",
  "したまち",
  "がっぺい",
  "しゅだい",
  "おくらす",
  "あんじる",
  "ころがる",
  "いじわる",
  "さびしい",
  "せいさく",
  "みなもと",
  "おんけい",
  "ちゅうい",
  "しょうち",
  "かくだい",
  "つらねる",
  "いんきょ",
  "ねかせる",
  "ぎんこう",
  "そのころ",
  "ふくらむ",
  "かくじつ",
  "できもの",
  "よふかし",
  "かんじる",
  "だいじん",
  "うんそう",
  "はぐるま",
  "たかまる",
  "めーたー",
  "うらぎる",
  "どんぶり",
  "ぞんじる",
  "てんせん",
  "せんでん",
  "とつぜん",
  "くうそう",
  "あっぱく",
  "せいぞん",
  "かんせい",
  "ぜんいん",
  "うけつけ",
  "こぼれる",
  "しゅくが",
  "まんぞく",
  "そうなん",
  "がんねん",
  "めでぃあ",
  "せいさん",
  "ぐうぜん",
  "すいみん",
  "とうさん",
  "げじゅん",
  "ゆるやか",
  "かなえる",
  "だいべん",
  "かつやく",
  "でざいん",
  "まちがう",
  "がいとう",
  "ゆうのう",
  "えんぽう",
  "しょばつ",
  "しなやか",
  "ほんしつ",
  "じんぶつ",
  "じょうほ",
  "せいけん",
  "まぶしい",
  "ものおき",
  "めんする",
  "あけがた",
  "ひのまる",
  "げんろん",
  "さくじょ",
  "がいねん",
  "おっけー",
  "せんたー",
  "しょっき",
  "まるまる",
  "こうたい",
  "ぶつける",
  "じったい",
  "ほんもの",
  "ちんもく",
  "すきずき",
  "なにぶん",
  "いじめる",
  "しばらく",
  "ふんいき",
  "たすける",
  "こーらす",
  "くうふく",
  "あわせる",
  "たとえる",
  "さつじん",
  "おやすみ",
  "ふっかつ",
  "あぱーと",
  "くじびき",
  "きかざる",
  "どうふう",
  "さいれん",
  "とけこむ",
  "ねっしん",
  "こんばん",
  "ともかく",
  "でざーと",
  "ゆうがた",
  "だいほん",
  "ひとしい",
  "こんどう",
  "もたらす",
  "ほうがく",
  "しなびる",
  "はいしゃ",
  "こうしゃ",
  "うかがう",
  "だんぜん",
  "じぎょう",
  "いいわけ",
  "ばってん",
  "せんせい",
  "あつかう",
  "ぶんめい",
  "ひょうし",
  "ひところ",
  "らいねん",
  "ぽすたー",
  "ちゃのま",
  "まんまえ",
  "わりざん",
  "じんじゃ",
  "きみょう",
  "せっかく",
  "はりがね",
  "ちゃのゆ",
  "まったく",
  "ほとんど",
  "ほうてい",
  "うろうろ",
  "てってい",
  "ながなが",
  "ますます",
  "しりぞく",
  "さくもつ",
  "がっしり",
  "ほうしん",
  "ぱとかー",
  "かいてん",
  "はかどる",
  "れでぃー",
  "すぴーち",
  "たちかた",
  "けつあつ",
  "それぞれ",
  "どんどん",
  "だんだん",
  "かんさつ",
  "どくせん",
  "つぎつぎ",
  "いちげん",
  "らくだい",
  "とうとい",
  "あんがい",
  "じょうふ",
  "はんざい",
  "ほがらか",
  "おもたい",
  "じじょう",
  "うらやむ",
  "ようがん",
  "すませる",
  "しつぼう",
  "いったい",
  "げんぞう",
  "ざんだか",
  "じゅけん",
  "けんぶつ",
  "あるばむ",
  "こづつみ",
  "かくべつ",
  "やしなう",
  "かそくど",
  "げいのう",
  "ふくめん",
  "れっすん",
  "ぶらぶら",
  "ぼうけん",
  "せんさい",
  "こうにん",
  "あれこれ",
  "いきがい",
  "じょうず",
  "ぜんこく",
  "すてれお",
  "さしひく",
  "そくざに",
  "せつやく",
  "せいぞう",
  "ぎじどう",
  "へんけん",
  "おしゃれ",
  "そんけい",
  "ほうかい",
  "こうせき",
  "てきすと",
  "のみこむ",
  "ちいさい",
  "いきいき",
  "げんそく",
  "ことわざ",
  "かくめい",
  "まいすう",
  "まじわる",
  "じょうか",
  "こうせん",
  "たんすう",
  "らんよう",
  "ぜんかい",
  "てがける",
  "ぎゃんぐ",
  "ひとつき",
  "すいへい",
  "かかげる",
  "ひろまる",
  "せっけん",
  "じんせい",
  "とうせい",
  "にくしみ",
  "わざわざ",
  "ほきゅう",
  "かいけつ",
  "せいしつ",
  "あとつぎ",
  "いっせい",
  "めんばー",
  "ふぃるむ",
  "ぞくぞく",
  "するどい",
  "だきょう",
  "せいじん",
  "しつもん",
  "じゅうし",
  "でたらめ",
  "はきはき",
  "せっせと",
  "ろんじる",
  "たすかる",
  "せいしん",
  "ついほう",
  "ついやす",
  "しゅさい",
  "ゆうだち",
  "したしい",
  "しょてい",
  "もちろん",
  "ういるす",
  "かんぜい",
  "ひこうき",
  "おでかけ",
  "さくせい",
  "めざまし",
  "ふろんと",
  "なになに",
  "ごうとう",
  "つうじる",
  "らくのう",
  "きちっと",
  "あくせる",
  "たいがい",
  "ぎきょく",
  "せんしゅ",
  "たまたま",
  "じどうし",
  "しはらう",
  "なめらか",
  "れいてん",
  "ちゃわん",
  "だいやる",
  "てつだい",
  "えきたい",
  "いちばん",
  "だぶだぶ",
  "もんだい",
  "ぜんしん",
  "てんけい",
  "めいろう",
  "またがる",
  "せきどう",
  "たっせい",
  "こいしい",
  "かきとり",
  "しゃこう",
  "うりだす",
  "くしゃみ",
  "くちべに",
  "さーくる",
  "かいうん",
  "しっかく",
  "しあわせ",
  "しんけん",
  "かろりー",
  "みだれる",
  "ごういん",
  "まとまり",
  "ちゅうし",
  "ていねん",
  "かわいい",
  "もしくは",
  "ぽいんと",
  "ちょうめ",
  "おうせつ",
  "かならず",
  "どうとう",
  "おぼえる",
  "たいりく",
  "はいゆう",
  "げんきん",
  "くりーむ",
  "つうやく",
  "ちぢれる",
  "こうけん",
  "けいとう",
  "めんどう",
  "ようそう",
  "ばいきん",
  "てあらい",
  "てんたい",
  "ゆるめる",
  "かいはつ",
  "どうかん",
  "ちかぢか",
  "しんがく",
  "とらんぷ",
  "ほしょう",
  "そのまま",
  "てーぶる",
  "つかまる",
  "みなおす",
  "しんこう",
  "したどり",
  "ちょくご",
  "えんせん",
  "ぶっそう",
  "あやまり",
  "はりがみ",
  "てんじる",
  "せいぜん",
  "いねむり",
  "てんかい",
  "えいえん",
  "たいかい",
  "しょるい",
  "まっくら",
  "つうずる",
  "ききとり",
  "せいぶつ",
  "ひっこむ",
  "ゆっくり",
  "えんげき",
  "ちゃいむ",
  "けっさく",
  "うるおう",
  "しゃらく",
  "けつだん",
  "しゅやく",
  "まんてん",
  "さしだす",
  "おちつき",
  "それほど",
  "たどうし",
  "かたがた",
  "すうがく",
  "くちびる",
  "とこのま",
  "しきりに",
  "かいすう",
  "きゃりあ",
  "すいぶん",
  "はたして",
  "たいめん",
  "ぞうきん",
  "まずしい",
  "しんじる",
  "はやくち",
  "ほんにん",
  "かくにん",
  "こうてい",
  "しんそう",
  "こっかい",
  "どひょう",
  "でんらい",
  "さいしょ",
  "とくゆう",
  "ようもう",
  "おろそか",
  "ないたー",
  "ゆびさす",
  "あつめる",
  "ゆうわく",
  "ゆうびん",
  "でんせん",
  "かいけい",
  "こうかん",
  "ぶんげい",
  "はたらく",
  "わかれる",
  "ねんせい",
  "どくさい",
  "まじえる",
  "こくばん",
  "そのうえ",
  "のこらず",
  "おどろく",
  "さくぶつ",
  "いったん",
  "じっこう",
  "かんとう",
  "さいよう",
  "しあがり",
  "しんくう",
  "のこぎり",
  "ちょうわ",
  "とりひき",
  "しょくじ",
  "むじゅん",
  "はっけん",
  "さいそく",
  "さいきん",
  "ふんがい",
  "はつおん",
  "あんてな",
  "あるいは",
  "まっくろ",
  "ばいてん",
  "ていぼう",
  "ぼんやり",
  "とうたつ",
  "とうとう",
  "じゃぐち",
  "かいせい",
  "あざやか",
  "おうだん",
  "こなごな",
  "さいばん",
  "すいさん",
  "すたれる",
  "みじかい",
  "しょうみ",
  "むりょう",
  "たいへん",
  "いやしい",
  "のんびり",
  "あぶらえ",
  "そうごう",
  "みはらし",
  "うんてん",
  "ざつおん",
  "えんぴつ",
  "みかける",
  "とりにく",
  "ふくすう",
  "しじゅう",
  "とんねる",
  "かなしい",
  "あずかる",
  "やくしょ",
  "けしょう",
  "でんぽう",
  "けいぞく",
  "ろんそう",
  "ておくれ",
  "ぽけっと",
  "けってん",
  "こくりつ",
  "なるたけ",
  "こづかい",
  "おりもの",
  "はんのう",
  "すぷーん",
  "こーなー",
  "じんしゅ",
  "びたみん",
  "えんとつ",
  "ためらう",
  "あらすじ",
  "おぎなう",
  "つながる",
  "にくたい",
  "いわゆる",
  "せっとく",
  "こうぶつ",
  "どうやら",
  "あらわれ",
  "いきおい",
  "すらいど",
  "しんせい",
  "こんなに",
  "あじわう",
  "こうばい",
  "せいだく",
  "せつじつ",
  "あちこち",
  "くださる",
  "しんぼう",
  "ようてん",
  "せいべつ",
  "てぬぐい",
  "えいゆう",
  "きいろい",
  "けんかい",
  "つたわる",
  "ぐんたい",
  "げんこう",
  "しんりん",
  "そうどう",
  "れぽーと",
  "くわしい",
  "でこぼこ",
  "ぼくちく",
  "のうこう",
  "しみじみ",
  "かいらん",
  "いちどう",
  "へんかく",
  "たんけん",
  "ないりく",
  "おどろき",
  "こうとう",
  "らっかん",
  "ねんりん",
  "ひきとる",
  "せいれき",
  "きゅうに",
  "せんこう",
  "ゆうこう",
  "すうはい",
  "らじかせ",
  "じっさい",
  "ふりだす",
  "がいかん",
  "うんどう",
  "あまくち",
  "かさなる",
  "なまける",
  "つつしむ",
  "しょぞく",
  "くれーん",
  "とらえる",
  "せーたー",
  "ときどき",
  "まにあう",
  "みおくり",
  "くっせつ",
  "はじまり",
  "かおつき",
  "じゃんぷ",
  "とうそつ",
  "そのため",
  "こうりょ",
  "じゅーす",
  "おびえる",
  "せいかつ",
  "くずれる",
  "ひろがる",
  "おどおど",
  "ちやほや",
  "がいこう",
  "すいでん",
  "じょじょ",
  "みりょく",
  "まごまご",
  "どろぼう",
  "すいとう",
  "こっそり",
  "ぎょそん",
  "きんろう",
  "ばったり",
  "おみまい",
  "きびしい",
  "こうえき",
  "にんげん",
  "こしかけ",
  "ぜんぱん",
  "そくたつ",
  "かんゆう",
  "しんじゅ",
  "かみさま",
  "けっかん",
  "ゆーもあ",
  "かーてん",
  "ことわる",
  "かんげき",
  "きょだい",
  "ふかまる",
  "でんしゃ",
  "きまじめ",
  "きんべん",
  "くんれん",
  "そくする",
  "そしょう",
  "ことなる",
  "ふうぞく",
  "かんがい",
  "なぷきん",
  "ふざける",
  "つきあい",
  "よわめる",
  "ふきそく",
  "いんかん",
  "にんしき",
  "あらわす",
  "でぱーと",
  "わがまま",
  "せんとう",
  "けいこう",
  "おうさま",
  "さからう",
  "ずぶぬれ",
  "はんにん",
  "こくせき",
  "きたない",
  "それから",
  "なにとぞ",
  "いよいよ",
  "こないだ",
  "ほろぼす",
  "みせもの",
  "すいせん",
  "じょゆう",
  "いっそう",
  "わりこむ",
  "ぼつぼつ",
  "ぱそこん",
  "てのひら",
  "げんじつ",
  "つとめて",
  "ちんれつ",
  "とぎれる",
  "ふわふわ",
  "もとづく",
  "よしあし",
  "はんかち",
  "ぶれーき",
  "せきにん",
  "しょうゆ",
  "むらさき",
  "なまいき",
  "てつがく",
  "しはらい",
  "はいたつ",
  "すたじお",
  "はげます",
  "きゃんぷ",
  "がいろん",
  "ますこみ",
  "こうばん",
  "かんどう",
  "いちよう",
  "おしこむ",
  "けいかく",
  "まいなす",
  "ほうずる",
  "おんせん",
  "よろしく",
  "いんてり",
  "じゅよう",
  "ようとう",
  "おさえる",
  "ますたー",
  "おおすじ",
  "けんよう",
  "らけっと",
  "すすめる",
  "ちかてつ",
  "めいめい",
  "たんどく",
  "とうばん",
  "れいがい",
  "けとばす",
  "ぴかぴか",
  "しきたり",
  "ひるめし",
  "ちゃねる",
  "みとおし",
  "となえる",
  "けっしん",
  "どんなに",
  "こころみ",
  "こくさい",
  "いんたい",
  "もともと",
  "すとろー",
  "はちみつ",
  "みつかる",
  "ごうかく",
  "うんぱん",
  "のろのろ",
  "ざつだん",
  "しゃわー",
  "みんよう",
  "さいなん",
  "ざいせい",
  "はんだん",
  "おはよう",
  "ぱーてぃ",
  "ひきいる",
  "ちょきん",
  "どくしょ",
  "こういん",
  "ちんでん",
  "しゃりん",
  "とらっく",
  "やくひん",
  "ですから",
  "こくみん",
  "ちょっと",
  "てきおう",
  "くうこう",
  "どうにか",
  "もくてき",
  "じょがい",
  "はりきる",
  "じっけん",
  "はいせん",
  "さだまる",
  "むなしい",
  "せいけつ",
  "べっそう",
  "でんたつ",
  "あらそい",
  "しゅうき",
  "ふんすい",
  "ぶんがく",
  "いちにち",
  "げきだん",
  "しゅうい",
  "しめきり",
  "ぶつかる",
  "せんすい",
  "たいせん",
  "ゆうれい",
  "かまえる",
  "すっかり",
  "つまずく",
  "あきれる",
  "くみあい",
  "おやゆび",
  "ざんきん",
  "おだやか",
  "たちよる",
  "てっこう",
  "ちゅうこ",
  "せいふく",
  "たいさく",
  "おしいれ",
  "みんしゅ",
  "しょっく",
  "かねぐら",
  "はじめて",
  "おじさん",
  "よしゅう",
  "あくまで",
  "ちょーく",
  "つたえる",
  "ぶらうす",
  "そのほか",
  "はじめに",
  "つりかわ",
  "れじゃー",
  "はつみみ",
  "ふんとう",
  "ようぼう",
  "きっぱり",
  "おとうと",
  "なぜなら",
  "だいすき",
  "おうじょ",
  "つりがね",
  "しょみん",
  "ひとじち",
  "あふれる",
  "せともの",
  "びんづめ",
  "きんせん",
  "おふぃす",
  "もちきり",
  "めったに",
  "げんばく",
  "たいふう",
  "しゅざい",
  "おたがい",
  "てんのう",
  "きょうか",
  "こっこう",
  "よくばり",
  "うすめる",
  "かんこう",
  "ととのう",
  "かいぼう",
  "しきゅう",
  "からっぽ",
  "かんげい",
  "みにくい",
  "いちどに",
  "しょはん",
  "うやまう",
  "ほうげん",
  "のみもの",
  "みずうみ",
  "ぎょうじ",
  "はかない",
  "こうへい",
  "けつごう",
  "あくどい",
  "しゅほう",
  "はんこう",
  "のうそん",
  "はんせい",
  "やくにん",
  "ないぞう",
  "せんぱい",
  "こんぱす",
  "すてーき",
  "たのしむ",
  "そっくす",
  "おおやけ",
  "もうてん",
  "できごと",
  "うちけす",
  "はなよめ",
  "ころがす",
  "ないかく",
  "はっきり",
  "いこーる",
  "ゆうえき",
  "きんぎょ",
  "ほうこう",
  "けいさん",
  "たいよう",
  "うんちん",
  "はなはだ",
  "しばしば",
  "どようび",
  "うらなう",
  "すちーむ",
  "とだえる",
  "いつでも",
  "さいてい",
  "とくばい",
  "けいべつ",
  "それでも",
  "かんそく",
  "しりあい",
  "どういつ",
  "かんたん",
  "げんさん",
  "おおいに",
  "ぎちょう",
  "こまやか",
  "じんたい",
  "いんよう",
  "てつどう",
  "けつろん",
  "おうじる",
  "じーぱん",
  "めいわく",
  "めいしょ",
  "とどける",
  "ぜつぼう",
  "めざめる",
  "あからむ",
  "はんする",
  "ふしょう",
  "ふろしき",
  "せいとう",
  "えんまん",
  "ください",
  "あくしゅ",
  "えんそう",
  "みなれる",
  "めんかい",
  "じょうし",
  "つきあう",
  "しょくむ",
  "ぜったい",
  "たんしょ",
  "そうこう",
  "すいっち",
  "さんばし",
];

export default words;
